<template>
  <b-modal
    title="編輯團購數量"
    v-model="showQuantity"
    centered
    cancel-title="取消編輯"
    cancel-variant="danger"
    ok-title="確認送出"
    ok-variant="success"
    @ok="handleSubmit"
  >
    <div class="mb-2">Line 暱稱：{{ data.customer_name }}</div>
    <div>下單組數：{{ data.quantity }}</div>
    <div>單價：${{ round(data.price) }}</div>
    <div>金額：${{ round(data.total_price) }}</div>
    <b-form inline>
      <label class="mr-sm-2" for="inline-form-custom-select-pref"
        >請輸入新的組數：</label
      >
      <b-form-input
        class="mb-2 mr-sm-2 mb-sm-0 qty-input"
        type="number"
        v-model.number="quantity"
        :min="groupbuying.once_quantity || 1"
        :max="groupbuying.once_max_quantity || 10"
        :step="groupbuying.once_increase_quantity || 1"
        @change="handleInput"
      ></b-form-input>
    </b-form>

    <div>總金額：${{ totalPrice }}</div>
  </b-modal>
</template>

<script>
import groupbuyApi from "../../../apis/groupbuy";

export default {
  name: "GroupbuyingsQuantityModal",
  props: ["show", "data", "groupbuyingNumber", "groupbuying"],
  data() {
    return {
      quantity: 0,
    };
  },
  computed: {
    totalPrice() {
      return this.quantity * Number(this.data.price);
    },
    showQuantity: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    normalizedMin () {
      return this.groupbuying.once_quantity || 1
    },
    normalizedMax () {
      let status = +this.groupbuying.status.slice(0, 1);
      if (new Date(this.data.deadline_at) > new Date() && status !== 1 && status !== 4) {
        return this.data.quantity;
      }

      if (!this.groupbuying.once_max_quantity) {
        return 10
      }

      return this.groupbuying.once_max_quantity - (this.groupbuying.once_max_quantity - this.groupbuying.once_quantity) % this.groupbuying.once_increase_quantity
    },
  },

  watch: {
    "data.order_number"() {
      this.quantity = this.data.quantity;
    },
    "show" () {
      this.quantity = this.data.quantity;
    }
  },

  mounted() {
    this.quantity = this.data.quantity;
  },

  methods: {
    async handleSubmit() {
      try {
        const { data } = await groupbuyApi.updatePoyaGroupbuyingOrder(
          this.data.id,
          {
            quantity: this.quantity,
          }
        );
        console.log("data", data);
        this.$emit("updated");
      } catch (error) {
        if (error.response.data.message) {
          this.$swal.fire({
            type: "error",
            title: "錯誤",
            text: error.response.data.message
          });
        }
      }
    },
    handleInput (value) {
      this.$nextTick(() => {
        if (!value) {
          return this.quantity = this.normalizedMin;
        }

        if (+value < this.normalizedMin) {
          return this.quantity = this.normalizedMin;
        }

        if (+value > this.normalizedMax) {
          return this.quantity = this.normalizedMax;
        }

        if ((+value - this.normalizedMin) % this.groupbuying.once_increase_quantity !== 0) {
          return this.quantity = this.normalizedMin;
        }

        return this.quantity = value;
      })
    },
    round(value) {
      return Math.round(value);
    }
  },
};
</script>

<style>
.qty-input {
  width: 100px !important;
}
</style>
