<template>
  <div class="groupbuy-list">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>顧客訂單查詢</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">顧客訂單查詢</h4>
        </div>

        <div
          class="
            col-6
            d-flex
            justify-content-end
            align-items-start align-items-xl-center
          "
        >
        </div>
      </div>

      <div class="d-flex flex-wrap mb-3 justify-content-end">
        <div class="row-searchbar d-flex mb-2 mb-md-0 mr-md-2">
          <b-form-select
            class="mr-2"
            style="width: 120px; height: 45px"
            v-model="query.keyword_type"
            :options="options"
          ></b-form-select>
          <div class="w-100">
            <b-form-input
              v-model="query.keyword"
              placeholder="可輸入 手機號碼/會員卡號/訂單編號 查詢"
              @keyup.enter="getPoyaGroupbuyingOrders"
            ></b-form-input>
          </div>
        </div>

        <b-button @click="getPoyaGroupbuyingOrders"
          ><i class="fa fa-search"></i
        ></b-button>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="groupbuyingOrders"
        :fields="fields"
        :sort-by.sync="sortBy"
        :busy="isFetchGroupbuyingOrders"
      >
        <template #cell(order_number)="data">
          <router-link :to="{ name: 'GroupbuyingsDetail', params: {groupbuyID: data.item.groupbuying_id }}">
            {{data.item.order_number}}
          </router-link>
        </template>
        <template #cell(quantity)="data">
          <a v-if="displayEdit(data.item)" href="" @click.prevent="handleQuantity(data.item)">
            {{ data.item.quantity }}
          </a>
          <span v-else>{{ data.item.quantity }}</span>
        </template>
        <template #cell(cancel)="data">
          <b-button v-if="displayCancel(data.item)" href="" @click.prevent="handleCancelOrder(data.item)" variant="inverse-danger">取消</b-button>
        </template>
        <template #cell(status_label)="data">
          {{ data.item.status_label }}
          <template v-if="false && data.item.order.cancel_reason">
            <BIconQuestionCircle :id="`popover-tips-${data.item.id}`"></BIconQuestionCircle>
            <b-popover :target="`popover-tips-${data.item.id}`" triggers="hover focus click">
              <div class="text-left">
                {{ data.item.order.cancel_reason }}
              </div>
            </b-popover>
          </template>
        </template>
      </b-table>

      <GroupbuyingsQuantityModal
        v-if="editQuantityData"
        :show="isOpenQuantityModal"
        @update:show="isOpenQuantityModal = $event"
        @updated="getPoyaGroupbuyingOrders()"
        :data="editQuantityData"
        :groupbuying="editQuantityData.groupbuying"
        :groupbuyingNumber="editQuantityData.groupbuying.groupbuying_number"
      />

      <div
        class="
          d-flex
          justify-content-center
          align-items-center
          flex-column flex-md-row
        "
        style="margin-top: 80px"
      >
        <b-pagination
          class="separated mb-2 mb-md-0"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="groupbuyList"
          @change="handleChangePage"
        ></b-pagination>
        <div class="ml-2">
          每頁顯示數量<b-form-select
            v-model="query.per_page"
            :options="perPageOptions"
            style="width: 70px"
            class="ml-2"
            @change="handleChangePage"
          ></b-form-select>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import { format } from "date-fns";
import groupbuyApi from "@/apis/groupbuy";
import GroupbuyingsQuantityModal from "./PoyaGroupbuyingsQuantityModal";

export default {
  components: {
    GroupbuyingsQuantityModal,
  },

  data() {
    return {
      isOpenQuantityModal: false,
      editQuantityData: null,
      isFetchGroupbuyingOrders: false,
      groupbuyingOrders: [],
      sortBy: "created",
      query: {
        keyword: null,
        keyword_type: "customers.mobile_phone",
        page: 1,
        per_page: 15,
      },
      options: [
        {
          text: "手機號碼",
          value: "customers.mobile_phone",
        },
        {
          text: "會員編號",
          value: "customers.outer_code",
        },
        {
          text: "訂單編號",
          value: "orders.outer_order_number",
        },
      ],
      perPageOptions: [15, 50, 100],
      total: 0,
    }
  },

  computed: {
    fields() {
      return [
      // {
      //     key: "cancel",
      //     label: "操作",
      //   },
        {
          key: "groupbuying.group.branch.name",
          label: "開團分店",
        },
        {
          key: "order_number",
          label: "團購單編號",
        },
        {
          key: "customer_name",
          label: "LINE暱稱",
        },
        {
          key: "ec_member_no",
          label: "會員編號",
        },
        {
          key: "quantity",
          label: "下單組數",
          class: "text-center",
        },
        {
          key: "total_price",
          class: "text-right",
          label: "金額",
          formatter: (value) => {
            return value ? "$" + Number(value).toFixed(0) : "";
          },
        },
        {
          key: "order.outer_order_number",
          label: "線上訂單編號",
        },
        {
          key: "total_price",
          label: "實際金額",
        },
        {
          key: "order.order_at",
          label: "訂單成立時間",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        {
          key: "order.deadline_at",
          label: "取貨期限",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        {
          key: "status_label",
          label: "團購單狀態",
        },
      ];
    }
  },

  created() {
    const { keyword_type, keyword } = this.$route.query || {};
    if (keyword || keyword_type) {
      this.query.keyword = keyword;
      this.query.keyword_type = keyword_type;
    }
    // 預設不要查資料
    // this.getPoyaGroupbuyingOrders();
  },

  methods: {
    handleQuantity: function (data) {
      this.isOpenQuantityModal = true;
      this.editQuantityData = data;
    },

    handleCancelOrder: function (data) {
      console.log(data);
      const price = data.total_price ? "$" + Number(data.total_price).toFixed(0) : "";
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要取消嗎？",
          html: `
            <div class="d-block">
              <div class="my-3 text-left">
                <div>團購單編號：${data.order_number}</div>
                <div>線上訂單編號：${data.order.outer_order_number}</div>
                <div>下單組數：${data.quantity}</div>
                <div>金額：${price}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定取消",
          cancelButtonText: "不取消",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await groupbuyApi.cancelPoyaGroupbuyingOrder(
                data.id
              );
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "取消成功",
                  type: "success",
                });

                await this.getPoyaGroupbuyingOrders();
              }
            } catch (error) {
              console.error(error);
            }
          }
        });
    },

    getPoyaGroupbuyingOrders: async function () {
      this.isFetchGroupbuyingOrders = true;
      try {

        const { keyword, keyword_type, page, per_page } = this.query;

        let params = {
          page,
          per_page
        }

        if (keyword) {
          params.keyword_type = keyword_type;
          params.keyword = keyword;
        }

        let { data } = await groupbuyApi.getPoyaGroupbuyingOrders(params);

        this.groupbuyingOrders = data.data;
        this.total = data.meta.total;
        this.query.per_page = data.meta.per_page;
      } catch (err) {
        console.error(err);
        if (err.response.status == 404) {
            this.$swal.fire({
            title: "搜尋不到相關團購單",
            type: "warning",
          });

          this.groupbuyingOrders = [];
          this.total = 0;
          this.query.per_page = 15;
        }
      }

      this.isFetchGroupbuyingOrders = false;
    },
    displayCancel(item) {
      if (! this.$permissions.has(this.$permissions.consts.GROUPBUYING_ORDER_CANCEL)) {
        return false;
      }

      if (!item.status || !item.delivery_status) return false;
      if (item.status === 'Finish' && item.delivery_status === 'Finish') return false;
      if (item.status === 'Cancel') return false;
      return true;
    },
    displayEdit() {
    // displayEdit(item) {
      return false;

      // if (! this.$permissions.has(this.$permissions.consts.GROUPBUYING_ORDER_QTY_UPDATE)) {
      //   return false;
      // }

      // if (!item.status || !item.delivery_status) return false;
      // if (item.status === 'Finish' && item.delivery_status === 'Finish') return false;
      // if (item.status === 'Cancel') return false;
      // return true;
    },
    handleChangePage() {
      this.$nextTick(() => {
        this.getPoyaGroupbuyingOrders();
      });
    },
  },
}
</script>

<style lang="scss">
.popover {
  max-width: 600px;
}

.groupbuy-list {
  .nav-tabs .nav-link.active,
  .table thead {
    background: rgba(255, 98, 88, 0.2);
  }

  .row-searchbar {
    width: 35%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>
